.form{
    width: 100vw;
    height: 100vh;
    background-image: url('./imgs/bg-form.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .content{
        width: 400px;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        .title{
            position: absolute;
            top: -46px;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                margin-right: 30px;
            }
            p{
                font-size: 20px;
                padding-left: 30px;
                border-left: 1px solid #AAAAAA;
            }
        }
        .ipts{
            width: 100%;
            padding: 50px;
            text-align: left;
            // box-shadow: 0px 0px 40px 0px rgba(140, 181, 255, 0.2), 0px 0px 41px 0px rgba(255, 255, 255, 0.16), 0px 0px 9px 0px rgba(153, 165, 255, 0.64), -5px -6px 24px 0px rgba(75, 100, 255, 0.22);
            border-radius: 20px;
            background-color: #fff;
            h3{
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 60px;
            }
            p{
                font-size: 18px;
            }
            .ipt{
                border-bottom: 1px solid #e0e0e0;
                margin-bottom: 40px;
                position: relative;
                input{
                    border: 0;
                    outline-style: none;
                    width: 100%;
                    height: 36px;
                    background-color:transparent;
                }
                .getCode{
                    font-size: 16px;
                    color: #FF6038;
                    cursor: pointer;
                    position: absolute;
                    right: 10px;
                    top: 12px;
                }
                .no{
                    color: #d0cbcb;
                }
            }
            .btn{
                width: 100%;
                height: 50px;
                background: #FF6038;
                border-radius: 6px;
                line-height: 50px;
                font-size: 18px;
                color: #fff;
                margin-top: 80px;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    .content-q{
        width: 580px;
        .ipts{
            .btn{
                margin-top: 44px;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .form{
        .content{
            transform: translate(-50%,-50%)scale(0.7);
        }
        .content-q{
            transform: translate(-50%,-50%)scale(0.5);
        }
    }
}