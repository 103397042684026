.header{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 20px;
    .content{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .navs{
            display: flex;
            align-items: center;
            .nav{
                color: #fff;
                margin-right: 20px;
                cursor: pointer;
                padding-bottom: 12px;
            }
            .active{
                border-bottom: 2px solid #FF6038;
            }
        }
        .btn{
            width: 100px;
            height: 36px;
            border: 1px solid #FF6038;
            border-radius: 6px;
            text-align: center;
            line-height: 36px;
            color: #FF6038;
            font-size: 14px;
            cursor: pointer;
        }
    }
    // 手机端
    .s_content {
        display: none;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .top {
            width: 100%;
            text-align: right;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 40px;
            .s_logo {
                img {
                    width: 42px;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
            .close, .open{
                color: #fff;
            }
        }
        .select {
            width: 100%;
            text-align: left;
            text-indent: 2em;
            z-index: 2;
            margin-bottom: 20px;
            position: absolute;
            top: 40px;
            left: 0;
            background-color: #fff;
            height: 100vh;
            a{
                display: block;
                height: 42px;
                line-height: 42px;
                border-bottom:1px solid #80808033;
                cursor: pointer;
                font-size: 14px;
                color: #333;
            }
        }
    }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .header{
        .content{
            width: 90%;
        }
    }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
    .header{
        .content{
            width: 90%;
            margin: 0 auto;
            img{
                width: 80px;
            }
            .btn{
                width: 68px;
                height: 32px;
                line-height: 32px;
                font-size: 12px;
            }
            .navs{
                font-size: 12px;
                .nav{
                    margin-right: 14px;
                    padding-bottom: 6px;
                }
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .header{
        .content{
            display: none;
        }
        .s_content{
            display: block;
        }
    }
    
}

a{
    text-decoration: none;
}