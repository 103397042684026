.newList{
    background-color: #fff;
    .banner {
        position: relative;
        .banner-pic {
            width: 100%;
        }
    }
    .top{
        background-color: #F5F7FA;
        .bg{
            padding-top: 160px;
            padding-bottom: 70px;
            border-radius: 0px 220px 220px 0px;
            background-color: #fff;
            ul{
                width: 1200px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                li{
                    padding: 16px 10px;
                    width: 32%;
                    border: 1px solid #FF6038;
                    border-radius: 6px;
                    img{
                        width: 100%;
                        height: 240px;
                        margin-bottom: 30px;
                    }
                    h2{
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 24px;
                    }
                    p{
                        font-size: 16px;
                        line-height: 1.25;
                        display:-webkit-box;
                        -webkit-box-orient:vertical;
                        -webkit-line-clamp:2;
                        overflow:hidden;
                    }
                }
            }
        }
        
    }
    .newList2{
        display: flex;
        position: relative;
        height: 640px;
        .left{
            width: 50%;
            background-color: #fff;
        }
        .right{
            width: 50%;
            background-color: #F5F7FA;
        }
        .bg{
            position: absolute;
            width: 1200px;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            border-radius: 220px 0px 0px 220px;
            background-color: #F5F7FA;
            height: 100%;
            padding: 46px 12%;
            ul{
                width: 100%;
                border-top: 1px solid #E0E0E0;
                li::before{
                    content: '';
                    width: 8px;
                    height: 8px;
                    background: #FF6038;
                    position: absolute;
                    top: 46px;
                    left: 0;
                }
                li{
                    padding: 40px;
                    padding-left: 62px;
                    padding-right: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #E0E0E0;
                    position: relative;
                    .text{
                        width: 60%;
                        h2{
                            font-size: 20px;
                            font-weight: bold;
                        }
                        p{
                            font-size: 16px;
                            line-height: 1.25;
                            display:-webkit-box;
                            -webkit-box-orient:vertical;
                            -webkit-line-clamp:2;
                            overflow:hidden;
                            margin-top: 12px;
                            margin-bottom: 16px;
                        }
                        span{
                            font-size: 12px;
                            color: #999;
                        }
                    }
                    .yuan{
                        width: 46px;
                        height: 46px;
                        background: #FF6038;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        img{
                            width: 22px;
                        }
                    }
                }
            }
        }
    }
    .pages{
        margin-top: 40px;
        text-align: center;
        display: flex;
        justify-content: center;
    }
}

// 媒体查询
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .newList {
        .top{
            .bg{
                padding-top: 70px;
                ul{
                    width: 90%;
                    li{
                        img{
                            height: 200px;
                            margin-bottom: 20px;
                        }
                        h2{
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .newList2{
            height: 540px;
            .bg{
                width: 90%;
                ul{
                    li{
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
    .newList {
        .top{
            .bg{
                padding-top: 70px;
                ul{
                    width: 90%;
                    display: block;
                    li{
                        width: 80%;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        img{
                            height: 200px;
                            margin-bottom: 20px;
                        }
                        h2{
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .newList2{
            height: 540px;
            .bg{
                width: 90%;
                ul{
                    li{
                        padding-top: 20px;
                        padding-bottom: 20px;
                        padding-left: 30px;
                        .text{
                            width: 76%;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .newList {
        .top{
            .bg{
                padding-top: 40px;
                padding-bottom: 40px;
                ul{
                    width: 90%;
                    display: block;
                    li{
                        width: 90%;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        img{
                            height: 200px;
                            margin-bottom: 20px;
                        }
                        h2{
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .newList2{
            height: 500px;
            .bg{
                width: 90%;
                ul{
                    li{
                        padding-top: 20px;
                        padding-bottom: 20px;
                        padding-left: 20px;
                        .text{
                            width: 76%;
                            h2{
                                font-size: 14px;
                            }
                            p{
                                font-size: 12px;
                            }
                        }
                        .yuan{
                            width: 30px;
                            height: 30px;
                            img{
                                width: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}