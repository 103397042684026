.aboutUs {
    background-color: #fff;

    .banner {
        position: relative;

        .banner-pic {
            width: 100%;
        }
    }

    .about {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 170px;

        .content-bg {
            width: 80%;
            height: 550px;
            margin-right: 0;
            background-color: #F5F7FA;
            position: relative;

            .content {
                position: absolute;
                top: 74px;
                left: 106px;
                display: flex;
                width: calc(100% - 106px);
                .left {
                    margin-right: 90px;
                    width: 25%;
                    // width: 360px;
                    .title {
                        font-size: 48px;
                        color: #d1d1d1;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    .title-s {
                        font-size: 16px;
                        color: #3C3C3C;
                        position: relative;
                    }

                    .title-s::after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 2px solid #FF6038;
                        position: absolute;
                        bottom: -4px;
                        right: -8px;
                    }

                    .line-box {
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        color: #3C3C3C;
                        margin-top: 50px;
                        margin-bottom: 20px;
                        .line {
                            width: 80px;
                            height: 1px;
                            background-color: #FF6038;
                            margin-left: 14px;
                        }
                    }

                    h2 {
                        font-size: 26px;
                        margin-bottom: 46px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 1.25;
                        margin-bottom: 60px;
                    }

                    .btn {
                        width: 360px;
                        height: 66px;
                        background: #FF6038;
                        border-radius: 6px;
                        font-size: 18px;
                        color: #fff;
                        line-height: 66px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        position: relative;

                        img {
                            position: absolute;
                            top: 50%;
                            margin-top: -4px;
                            right: 20px;
                        }
                    }
                }

                .right {
                    padding-top: 74px;
                    // width: 640px;
                    width: 45%;
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }

    .cultures {
        background-image: url('./imgs/cultures-bg.jpg');
        background-size: 100% 100%;
        padding-top: 80px;
        padding-bottom: 220px;
        margin-bottom: 42px;

        .content {
            width: 1200px;
            margin: 0 auto;

            .culturesItems {
                width: 100%;

                .culturesItem {
                    display: flex;
                    justify-content: space-between;

                    .left{
                        width: 55%;
                        img{
                            width: 100%;
                        }
                    }
                    .right {
                        position: relative;
                        width: 40%;
                        // width: 460px;

                        .title {
                            font-size: 48px;
                            color: #d1d1d1;
                            font-weight: bold;
                        }

                        .title-s {
                            font-size: 16px;
                            color: #3C3C3C;
                            position: relative;
                            display: block;
                            text-align: right;
                            padding-right: 6px;
                        }

                        .title-s::after {
                            content: '';
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            border: 2px solid #FF6038;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                        }

                        h2 {
                            margin-top: 40px;
                            font-size: 24px;
                            font-weight: bold;
                            margin-bottom: 24px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 1.25;
                            margin-bottom: 40px;
                        }

                        .btn {
                            width: 360px;
                            height: 66px;
                            background: #FF6038;
                            border-radius: 6px;
                            font-size: 18px;
                            color: #fff;
                            line-height: 66px;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            position: relative;

                            img {
                                position: absolute;
                                top: 50%;
                                margin-top: -4px;
                                right: 20px;
                            }
                        }

                        .btns {
                            width: 112px;
                            height: 56px;
                            display: flex;
                            padding: 12px 0;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            background-color: #fff;
                            cursor: pointer;

                            .l {
                                width: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-right: 1px solid #E0E0E0;
                            }

                            .r {
                                width: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }

                    .left {}
                }
            }
        }
    }

    .map {
        margin-bottom: 140px;

        .content {
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .text {
                text-align: left;
                position: relative;

                .title {
                    font-size: 48px;
                    color: #d1d1d1;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .title-s {
                    font-size: 16px;
                    color: #3C3C3C;
                    position: relative;
                }

                .title-s::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 2px solid #FF6038;
                    position: absolute;
                    bottom: -4px;
                    right: -8px;
                }

                h3 {
                    font-size: 24px;
                    color: #FF6038;
                    margin-bottom: 60px;
                    margin-top: 67px;
                }

                p {
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    img {
                        margin-right: 8px;
                    }
                }

                .pic {
                    position: absolute;
                    bottom: -70px;
                    right: -122px;
                    z-index: 11;
                }
            }

            .baiduMap {
                width: 75%;
                height: 620px;
            }
        }
    }
}

// 媒体查询
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .aboutUs{
        .about{
            margin-bottom: 80px;
            .content-bg{
                .content{
                    top: 50px;
                    left: 60px;
                    width: calc(100% - 60px);
                    .left{
                        width: 32%;
                        margin-right: 40px;
                        .title{
                            font-size: 40px;
                        }
                        .line-box{
                            margin-top: 30px;
                        }
                        h2{
                            font-size: 20px;
                            margin-bottom: 30px;
                        }
                    }
                    .right{
                        padding-top: 60px;
                    }
                }
            }
        }
        .cultures{
            padding-top: 60px;
            padding-bottom: 140px;
            .content{
                width: 90%;
                .culturesItems {
                    width: 100%;
                    .culturesItem {
                        .left{
                            
                        }
                        .right {
                            .title{
                                font-size: 32px;
                            }
                            h2{
                                margin-top: 30px;
                                margin-bottom: 20px;
                            }
                            p{
                                margin-bottom: 30px;
                            }
                            .btn{
                                width: 300px;
                                height: 50px;
                                line-height: 50px;
                            }
                        }
                    }
                }
            }
        }
        .map{
            margin-bottom: 110px;
            .content{
                width: 90%;
            }
        }
    }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
    .aboutUs{
        .about{
            margin-bottom: 80px;
            .content-bg{
                height: 700px;
                .content{
                    top: 30px;
                    left: 40px;
                    width: calc(100% - 40px);
                    flex-direction: column-reverse;
                    .left{
                        width: 80%;
                        margin-right: 0px;
                        .title{
                            font-size: 40px;
                        }
                        .line-box{
                            margin-top: 30px;
                        }
                        h2{
                            font-size: 20px;
                            margin-bottom: 30px;
                        }
                        p{
                            margin-bottom: 30px;
                        }
                        .btn{
                            width: 260px;
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                        }
                    }
                    .right{
                        width: 80%;
                        padding-top: 20px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .cultures{
            padding-top: 60px;
            padding-bottom: 130px;
            .content{
                width: 90%;
                .culturesItems {
                    width: 100%;
                    .culturesItem {
                        flex-direction: column;
                        .left{
                            width: 100%;
                            margin-bottom: 20px;
                        }
                        .right {
                            width: 100%;
                            padding-bottom: 90px;
                            text-align: center;
                            .title{
                                font-size: 32px;
                            }
                            h2{
                                margin-top: 30px;
                                margin-bottom: 20px;
                            }
                            p{
                                margin-bottom: 30px;
                            }
                            .btn{
                                width: 300px;
                                height: 50px;
                                line-height: 50px;
                                margin: 0 auto;
                            }
                            .btns{
                                left: 50%;
                                transform: translate(-50%, 0);
                            }
                        }
                    }
                }
            }
        }
        .map{
            margin-bottom: 80px;
            .content{
                width: 90%;
                flex-direction: column;
                .text{
                    margin-bottom: 20px;
                    .pic{
                        right: 0;
                        bottom: 0;
                        width: 300px;
                    }
                }
                .baiduMap{
                    width: 100%;
                    height: 400px;
                }
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .aboutUs{
        .about{
            margin-bottom: 80px;
            .content-bg{
                width: 90%;
                height: 620px;
                .content{
                    top: 30px;
                    left: 40px;
                    width: calc(100% - 40px);
                    flex-direction: column-reverse;
                    .left{
                        width: 80%;
                        margin-right: 0px;
                        .title{
                            font-size: 40px;
                        }
                        .line-box{
                            margin-top: 30px;
                        }
                        h2{
                            font-size: 16px;
                            margin-bottom: 30px;
                        }
                        p{
                            font-size: 14px;
                            margin-bottom: 30px;
                        }
                        .btn{
                            width: 200px;
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                        }
                    }
                    .right{
                        width: 80%;
                        padding-top: 20px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .cultures{
            padding-top: 60px;
            padding-bottom: 130px;
            .content{
                width: 90%;
                .culturesItems {
                    width: 100%;
                    .culturesItem {
                        flex-direction: column;
                        .left{
                            width: 100%;
                            margin-bottom: 20px;
                        }
                        .right {
                            width: 100%;
                            padding-bottom: 90px;
                            text-align: center;
                            .title{
                                font-size: 32px;
                            }
                            h2{
                                margin-top: 30px;
                                margin-bottom: 20px;
                            }
                            p{
                                margin-bottom: 30px;
                            }
                            .btn{
                                width: 300px;
                                height: 50px;
                                line-height: 50px;
                                margin: 0 auto;
                            }
                            .btns{
                                left: 50%;
                                transform: translate(-50%, 0);
                            }
                        }
                    }
                }
            }
        }
        .map{
            margin-bottom: 60px;
            .content{
                width: 90%;
                flex-direction: column;
                .text{
                    margin-bottom: 20px;
                    .pic{
                        right: 0;
                        bottom: 80px;
                        width: 200px;
                    }
                }
                .baiduMap{
                    width: 100%;
                    height: 300px;
                }
            }
        }
    }
}