.footer{
    margin-top: 60px;
    background-color: #141621;
    padding-bottom: 58px;
    .content{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 58px 0;
        text-align: left;
        h5{
            color: #FFF;
            font-size: 16px;
            margin-bottom: 28px;
        }
        p{
            color: #79797d;
            font-size: 14px;
            margin-bottom: 18px;
            cursor: pointer;
        }
        .hotline{
            .num{
                display: flex;
                align-items: center;
                margin-bottom: 38px;
                img{
                    margin-right: 18px;
                }
                span{
                    font-size: 24px;
                    color: #FF6038;
                    font-weight: bold;
                }
            }
        }
        .erweima{
            text-align: center;
        }
        .channel{
            p{
                width: 100px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
        }
    }
    .text{
        font-size: 14px;
        color: #FFFFFF;
        margin-bottom: 8px;
        text-align: center;
    }
    .beian{
        font-size: 14px;
        color: #FFFFFF;
        margin-bottom: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0 20px;
        a{
            color: #fff !important;
            margin-right: 5px;
        }
    }
}

// 媒体查询
@media screen and (min-width: 992px) and (max-width: 1200px) {
 .footer{
   .content{
       width: 90%;
   }
 }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
 .footer{
     padding-bottom: 20px;
   .content{
       width: 90%;
       flex-wrap: wrap;
       padding: 30px 0;
       .erweima{
           margin: 0 auto;
       }
   }
 }
}
@media screen and (max-width: 576px) {
 .footer{
     padding-bottom: 20px;
   .content{
       width: 90%;
       flex-wrap: wrap;
       padding: 30px 0;
       .erweima{
           margin: 0 auto;
       }
       .channel, .solution, .about{
           display: none;
       }
   }
 }
}