.home {
    .banner {
        position: relative;

        .banner-pic {
            width: 100%;
        }

        .message {
            width: 1000px;
            height: 260px;
            margin: 0 auto;
            background: #FFFFFF;
            box-shadow: 0px 6px 26px 0px rgba(23, 96, 229, 0.1);
            border-radius: 20px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            bottom: -130px;
            padding: 40px 80px;
            display: flex;
            z-index: 1;

            .left {
                padding-right: 140px;
                margin-right: 80px;
                display: flex;
                position: relative;

                .about {
                    font-size: 48px;
                    color: #d1d1d1;
                    transform: rotate(90deg);
                    margin-right: 10px;
                    font-weight: bold;
                    position: absolute;
                    top: 64px;
                    left: -52px;
                }

                .vertical {
                    position: absolute;
                    width: 16px;
                    font-size: 16px;
                    color: #3C3C3C;
                    top: 18px;
                    left: 54px;
                }

                .vertical::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 2px solid #FF6038;
                    position: absolute;
                    bottom: -4px;
                    right: -8px;
                }

                .line {
                    width: 1px;
                    height: 120px;
                    background-color: #E0E0E0;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -60px;
                }
            }

            .right {
                .title {
                    font-size: 24px;
                    font-weight: bold;
                    margin-bottom: 12px;
                }

                .text {
                    font-size: 16px;
                    line-height: 1.25;
                    margin-bottom: 30px;
                }

                .btn {
                    width: 200px;
                    height: 46px;
                    background: #FF6038;
                    border-radius: 6px;
                    font-size: 18px;
                    color: #fff;
                    line-height: 46px;
                    padding-left: 54px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    img {
                        margin-left: 32px;
                    }
                }
            }
        }
        .message-s{
            display: none;
            width: 80%;
            background: #FFFFFF;
            box-shadow: 0px 6px 26px 0px rgba(23, 96, 229, 0.1);
            border-radius: 20px;
            padding: 20px 30px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            bottom: -140px;
            z-index: 1;
            .about{
                font-size: 24px;
                margin-bottom: 10px;
            }
            .vertical{
                font-size: 14px;
                margin-bottom: 20px;
            }
            .text{
                font-size: 12px;
                line-height: 1.5;
                margin-bottom: 10px;
            }
            .btn{
                width: 160px;
                height: 40px;
                background: #FF6038;
                border-radius: 6px;
                font-size: 14px;
                color: #fff;
                line-height: 40px;
                padding-left: 30px;
                display: flex;
                align-items: center;
                cursor: pointer;
                img {
                    margin-left: 32px;
                }
            }
        }
    }

    .advantage {
        background-color: #fff;
        height: 420px;
        position: relative;

        .content {
            width: 1200px;
            position: absolute;
            top: 200px;
            left: 50%;
            transform: translate(-50%, 0);

            .title {
                font-size: 48px;
                color: #d1d1d1;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .title-s {
                font-size: 16px;
                color: #3C3C3C;
                position: relative;
            }

            .title-s::after {
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 2px solid #FF6038;
                position: absolute;
                bottom: -4px;
                right: -8px;
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 46px;

                li {
                    width: 32%;
                    background: #FFFFFF;
                    box-shadow: 0px 6px 26px 0px rgba(23, 96, 229, 0.1);
                    border-radius: 20px;
                    padding: 30px 15px;
                    display: flex;
                    justify-content: space-between;

                    .left {
                        width: 50%;

                        h2 {
                            font-size: 24px;
                            color: #1760E5;
                            margin-bottom: 18px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 1.5;
                            margin-bottom: 30px;
                            display: -webkit-box;
                            /**对象作为伸缩盒子模型展示**/
                            -webkit-box-orient: vertical;
                            /**设置或检索伸缩盒子对象的子元素的排列方式**/
                            -webkit-line-clamp: 3;
                            /**显示的行数**/
                            overflow: hidden;
                            /**隐藏超出的内容**/
                        }

                        .btn {
                            width: 160px;
                            height: 46px;
                            background-color: #1760E5;
                            text-align: center;
                            line-height: 46px;
                            font-size: 18px;
                            cursor: pointer;
                            color: #fff;
                            border-radius: 6px;
                        }
                    }
                }
            }
        }
    }

    .case {
        width: 1200px;
        background-color: #fff;
        margin: 0 auto;
        margin-top: 200px;
        box-shadow: 0px 6px 70px 0px rgba(23, 96, 229, 0.1);
        border-radius: 40px;
        margin-bottom: 70px;

        .caseItems {
            width: 100%;
            padding: 80px 42px 126px 42px;

            .caseItem {
                display: flex;
                justify-content: space-between;

                .left {
                    width: 30%;

                    .title {
                        font-size: 48px;
                        color: #d1d1d1;
                        font-weight: bold;
                    }

                    .title-s {
                        font-size: 16px;
                        color: #3C3C3C;
                        position: relative;
                    }

                    .title-s::after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 2px solid #FF6038;
                        position: absolute;
                        bottom: -4px;
                        right: -8px;
                    }

                    h2 {
                        margin-top: 40px;
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 24px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 1.25;
                        margin-bottom: 40px;
                        display: -webkit-box;
                        /**对象作为伸缩盒子模型展示**/
                        -webkit-box-orient: vertical;
                        /**设置或检索伸缩盒子对象的子元素的排列方式**/
                        -webkit-line-clamp: 2;
                        /**显示的行数**/
                        overflow: hidden;
                        /**隐藏超出的内容**/
                    }

                    .btns {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;

                        .btn {
                            width: 48%;
                            height: 40px;
                            background: #FF6038;
                            border-radius: 6px;
                            font-size: 14px;
                            color: #fff;
                            line-height: 40px;
                            text-align: center;
                            cursor: pointer;
                        }
                    }
                }

                .right {
                    width: 48%;
                    img{
                        width: 100%;
                    }
                }
            }
        }

        .case_dots {
            left: -16%;
            bottom: 10%;

            .slick-active {
                button {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #FF6038;
                    border: 0;
                }
            }

            li {
                width: 16px !important;

                button {
                    width: 8px;
                    height: 8px;
                    border: 1px solid #3C3C3C;
                    border-radius: 50%;
                }
            }
        }
    }

    .news {
        margin-bottom: 78px;

        .content {
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .title-box {
                .title {
                    font-size: 48px;
                    color: #d1d1d1;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .title-s {
                    font-size: 16px;
                    color: #3C3C3C;
                    position: relative;
                }

                .title-s::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 2px solid #FF6038;
                    position: absolute;
                    bottom: -4px;
                    right: -8px;
                }
            }

            ul {
                padding-top: 30px;
                width: 68%;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 28px 0;
                    border-bottom: 1px solid #E0E0E0;

                    .left {
                        display: flex;
                        align-items: center;
                        flex: 1;

                        .date {
                            font-size: 14px;
                            color: #999999;
                            margin-right: 16px;
                        }

                        .label {
                            width: 42px;
                            height: 16px;
                            background: #FF6038;
                            border-radius: 8px;
                            line-height: 16px;
                            font-size: 12px;
                            color: #fff;
                            text-align: center;
                            margin-right: 42px;
                        }

                        h2 {
                            font-size: 20px;
                            font-weight: bold;
                            margin-right: 42px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 1.25;
                            width: 40%;
                            display: -webkit-box;
                            /**对象作为伸缩盒子模型展示**/
                            -webkit-box-orient: vertical;
                            /**设置或检索伸缩盒子对象的子元素的排列方式**/
                            -webkit-line-clamp: 2;
                            /**显示的行数**/
                            overflow: hidden;
                            /**隐藏超出的内容**/
                        }
                    }

                    .btn {
                        width: 46px;
                        height: 46px;
                        background: #FF6038;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 22px;
                        }
                    }
                }
            }
        }
    }

    .raiders {
        margin-bottom: 78px;

        .content {
            width: 1200px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                color: #d1d1d1;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .title-s {
                font-size: 16px;
                color: #3C3C3C;
                position: relative;
            }

            .title-s::after {
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 2px solid #FF6038;
                position: absolute;
                bottom: -4px;
                right: -8px;
            }

            ul {
                margin-top: 46px;
                background: #FFFFFF;
                box-shadow: 0px 6px 70px 0px rgba(23, 96, 229, 0.1);
                border-radius: 40px;
                display: flex;
                justify-content: space-between;

                li {
                    width: 33%;
                    padding: 38px 30px;
                    transition: all .3s;

                    .pic {
                        width: 100%;
                        margin-bottom: 30px;
                    }

                    h2 {
                        font-size: 18px;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 16px;
                        color: #666666;
                        margin-bottom: 30px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                    }

                    .btn {
                        width: 160px;
                        height: 46px;
                        background: #FF6038;
                        border-radius: 6px;
                        font-size: 18px;
                        color: #fff;
                        line-height: 46px;
                        padding-left: 36px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        img {
                            width: 30px;
                            margin-left: 12px;
                        }
                    }
                }

                li:nth-child(2) {
                    border-left: 1px solid #E0E0E0;
                    border-right: 1px solid #E0E0E0;
                }

                li:hover {
                    box-shadow: 0px 6px 70px 0px rgba(23, 96, 229, 0.1);
                }
            }
        }
    }

    .serve {
        margin-bottom: 90px;

        .content {
            width: 1200px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                color: #d1d1d1;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .title-s {
                font-size: 16px;
                color: #3C3C3C;
                position: relative;
            }

            .title-s::after {
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 2px solid #FF6038;
                position: absolute;
                bottom: -4px;
                right: -8px;
            }

            ul {
                margin: 60px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                li {
                    text-align: center;

                    .yuan {
                        width: 160px;
                        height: 160px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 16px 0px rgba(23, 96, 229, 0.08);
                        border-radius: 50%;
                        line-height: 160px;
                        text-align: center;
                        margin: 0 auto;
                        margin-bottom: 20px;
                    }

                    h2 {
                        font-size: 24px;
                        color: #FF6038;
                        margin-bottom: 12px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 1.5;
                    }
                }
            }

            .btn {
                margin: 0 auto;
                width: 360px;
                height: 66px;
                background: #FF6038;
                border-radius: 6px;
                font-size: 18px;
                color: #fff;
                line-height: 66px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;

                img {
                    position: absolute;
                    top: 50%;
                    margin-top: -4px;
                    right: 20px;
                }
            }
        }
    }

    .distributed {
        padding-top: 80px;
        min-height: 800px;
        background-image: url('./imgs/map.png');
        background-size: 100%;
        background-repeat: no-repeat;

        .content {
            width: 1200px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                color: #d1d1d1;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .title-s {
                font-size: 16px;
                color: #3C3C3C;
                position: relative;
            }

            .title-s::after {
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 2px solid #FF6038;
                position: absolute;
                bottom: -4px;
                right: -8px;
            }

            .number {
                margin-top: 46px;
                display: flex;
                justify-content: space-around;

                li {
                    text-align: center;
                    width: 33%;

                    h2 {
                        font-size: 48px;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    span {
                        color: #666666;
                        font-size: 16px;
                    }
                }

                li:nth-child(2) {
                    border-left: 1px solid #bccae6;
                    border-right: 1px solid #bccae6;
                }
            }
        }
    }
}

// 媒体查询
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .home {
        .banner {
            .message {
                width: 80%;
                height: 200px;
                bottom: -100px;
                padding: 22px 80px;
                .left{
                    .about{
                        font-size: 40px;
                    }
                    .vertical{
                        top: 30px;
                        left: 42px;
                    }
                }
            }
        }
        .advantage{
            .content{
                width: 90%;
                top: 160px;
                ul{
                    li{
                        .left{
                            h2{
                                font-size: 20px;
                            }
                            p{
                                font-size: 14px;
                            }
                            .btn{
                                width: 100px;
                                height: 30px;
                                line-height: 30px;
                                font-size: 14px;
                            }
                        }
                        img{
                            width: 48%;
                        }
                    }
                }
            }
        }
        .case{
            width: 90%;
            margin-top: 160px;
            .caseItems {
                padding: 60px 42px 80px 42px;
                .caseItem {
                    .left{
                        .title{
                            font-size: 40px;
                        }
                    }
                }
            }
        }
        .news{
            .content{
                width: 90%;
            }
        }
        .raiders{
            .content{
                width: 90%;
            }
        }
        .serve{
            .content{
                width: 90%;
            }
        }
        .distributed{
            padding-top: 40px;
            min-height: 400px;
            .content{
                width: 90%;
                .number{
                    li{
                        h2{
                            font-size: 40px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
    .home {
        .banner {
            .message {
                display: none;
            }
            .message-s{
                display: block;
            }
        }
        .advantage{
            position: static;
            height: auto;
            margin-top: 200px;
            padding-top: 40px;
            .content{
                width: 90%;
                top: 170px;
                position: static;
                transform: translate(0, 0);
                margin: 0 auto;
                ul{
                    margin-top: 40px;
                    display: block;
                    padding-bottom: 40px;
                    li{
                        position: inherit;
                        width: 80%;
                        margin: 0 auto;
                        padding: 30px;
                        margin-bottom: 20px;
                        .left{
                            h2{
                                font-size: 20px;
                            }
                            p{
                                font-size: 14px;
                            }
                            .btn{
                                width: 100px;
                                height: 30px;
                                line-height: 30px;
                                font-size: 14px;
                            }
                        }
                        img{
                            width: 48%;
                        }
                    }
                }
            }
        }
        .case{
            width: 90%;
            margin-top: 40px;
            .caseItems {
                padding: 30px 42px 60px 42px;
                .caseItem {
                    display: block;
                    .left{
                        width: 100%;
                        margin-bottom: 20px;
                        .title{
                            font-size: 40px;
                        }
                    }
                    .right{
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }
            .case_dots{
                left: 0;
                bottom: 8%;
            }
        }
        .news{
            .content{
                display: block;
                width: 90%;
                ul{
                    width: 100%;
                }
            }
        }
        .raiders{
            .content{
                width: 90%;
                ul{
                    display: block;
                    li{
                        width: 100%;
                    }
                    li:nth-child(2){
                        border: 0;
                    }
                }
            }
        }
        .serve{
            margin-bottom: 60px;
            .content{
                width: 90%;
                ul{
                    flex-wrap: wrap;
                    li{
                        width: 48%;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .distributed{
            padding-top: 40px;
            min-height: 220px;
            .content{
                width: 90%;
                .title{
                    font-size: 36px;
                }
                .number{
                    margin-top: 30px;
                    li{
                        h2{
                            font-size: 26px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .home {
        .banner {
            .message {
                display: none;
            }
            .message-s{
                display: block;
                bottom: -120px;
                .about{
                    display: none;
                }
                .vertical{
                    margin-bottom: 14px;
                }
            }
        }
        .advantage{
            position: static;
            height: auto;
            margin-top: 160px;
            padding-top: 40px;
            .content{
                width: 90%;
                top: 170px;
                position: static;
                transform: translate(0, 0);
                margin: 0 auto;
                .title{
                    font-size: 30px;
                }
                ul{
                    margin-top: 40px;
                    display: block;
                    padding-bottom: 40px;
                    li{
                        position: inherit;
                        width: 90%;
                        margin: 0 auto;
                        padding: 30px;
                        margin-bottom: 20px;
                        .left{
                            h2{
                                font-size: 20px;
                            }
                            p{
                                font-size: 14px;
                            }
                            .btn{
                                width: 100px;
                                height: 30px;
                                line-height: 30px;
                                font-size: 14px;
                            }
                        }
                        img{
                            width: 48%;
                        }
                    }
                }
            }
        }
        .case{
            width: 90%;
            margin-top: 40px;
            .caseItems {
                padding: 30px 42px 60px 42px;
                .caseItem {
                    display: block;
                    .left{
                        width: 100%;
                        margin-bottom: 20px;
                        .title{
                            font-size: 30px;
                        }
                    }
                    .right{
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }
            .case_dots{
                left: 0;
                bottom: 8%;
            }
        }
        .news{
            .content{
                display: block;
                width: 90%;
                .title-box{
                    .title{
                        font-size: 30px;
                    }
                }
                ul{
                    width: 100%;
                    li{
                        .left{
                            .label{
                                display: none;
                            }
                            h2{
                               font-size: 14px;
                            }
                        }
                        .btn{
                            width: 30px;
                            height: 30px;
                            img{
                                width: 16px;
                            }
                        }
                    }
                }
            }
        }
        .raiders{
            .content{
                width: 90%;
                .title{
                    font-size: 30px;
                }
                ul{
                    display: block;
                    li{
                        width: 100%;
                    }
                    li:nth-child(2){
                        border: 0;
                    }
                }
            }
        }
        .serve{
            margin-bottom: 60px;
            .content{
                width: 90%;
                .title{
                    font-size: 30px;
                }
                ul{
                    display: block;
                    li{
                        width: 80%;
                        margin: 0 auto;
                        margin-bottom: 20px;
                    }
                }
                .btn{
                    width: 200px;
                    height: 40px;
                }
            }
        }
        .distributed{
            padding-top: 20px;
            min-height: 174px;
            .content{
                width: 90%;
                .title{
                    font-size: 24px;
                }
                .number{
                    margin-top: 30px;
                    li{
                        h2{
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}